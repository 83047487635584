import axios from 'axios';

export const geocode = async (query, bbox = '') => {
    const result = await axios.get(process.env.VUE_APP_MIERUNE_API_ENDPOINT, {
        params: {
            query,
            bbox,
            token: process.env.VUE_APP_MIERUNE_API_KEY,
        },
    });
    return result;
};
