import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
    {
        // トップページ
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/attribution',
        component: () =>
            import(/* webpackChunkName: "TradeDetailCode" */ '../views/Attribution.vue'),
    },
];

const router = new VueRouter({
    // ヒストリモード
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
