<!--地図画面コンポーネント-->
<template>
    <div id="mierune" :style="{ height: mapDiv.height }"></div>
</template>

<script>
import mapboxgl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';

import SearchBar from './SearchBar.vue';

const apiKey = process.env.VUE_APP_MAPTILER_API_KEY;
let map;
let marker;

export default {
    name: 'MapPane',
    component: { SearchBar },
    props: {
        selectedFeature: {
            type: Object,
        },
    },
    data() {
        return {
            mapDiv: {
                height: window.innerHeight - 48 + 'px',
            },
        };
    },
    computed: {},
    watch: {
        selectedFeature: {
            handler(val) {
                marker.remove();
                if (typeof val === 'undefined' || val === null) return;

                if (val.extent === null) {
                    const lnglat = val.geometry.coordinates;
                    this.panToLatlng(lnglat);
                } else {
                    this.panToExtent(val.extent);
                }
            },
        },
    },
    mounted: function () {
        this.createMap();
        window.addEventListener('resize', () => {
            this.mapDiv.height = window.innerHeight - 48 + 'px';
        });
    },
    methods: {
        createMap: function () {
            map = new mapboxgl.Map({
                container: 'mierune',
                style:
                    'https://api.maptiler.com/maps/jp-mierune-streets/style.json?key=' +
                    apiKey,
                center: [135.575, 34.065],
                zoom: 5.5,
                pitch: 60,
                hash: true,
                customAttribution:
                    '<a href="/attribution" target="_blank">利用データ</a>',
            });

            map.addControl(new mapboxgl.NavigationControl());

            marker = new mapboxgl.Marker({
                color: '#005773', // MIERUNEカラー
            });

            // 地図遷移ごとに現在のboundsを親コンポーネントで保存する
            this.$emit('setMapBounds', map.getBounds());
            const onMove = () => {
                this.$emit('setMapBounds', map.getBounds());
            };
            map.on('moveend', onMove);

            const onClick = (e) => {
                this.$emit('clicked', e.lngLat);
            };
            map.on('click', onClick);
        },
        panToExtent: function (extent) {
            const bottomLeft = new mapboxgl.LngLat(extent[0], extent[1]);
            const topLight = new mapboxgl.LngLat(extent[2], extent[3]);
            const bounds = new mapboxgl.LngLatBounds(bottomLeft, topLight);

            map.fitBounds(bounds, {
                padding: 20,
                maxZoom: 18,
                linear: true,
            });
        },
        panToLatlng: function (lnglat) {
            marker = new mapboxgl.Marker().setLngLat(lnglat).addTo(map);
            map.easeTo({
                center: lnglat,
                zoom: 16,
            });
        },
    },
};
</script>
