<template>
    <div :style="{ width: `${searchBarWidth}px` }">
        <v-autocomplete
            ref="autocomplete"
            :items="searchedItems"
            :search-input.sync="searchingText"
            label="Search..."
            clearable
            hide-details
            hide-no-data
            no-filter
            placeholder="地点を検索..."
            prepend-inner-icon="mdi-magnify"
            append-icon=""
            return-object
            color="#005773"
            solo
            rounded
            @input="$emit('setSelectedFeature', $event)"
        >
            <template slot="item" slot-scope="{ item }">
                <!-- 地物の種別に応じたアイコンの出し分け -->
                <div v-if="item.extent">
                    <v-icon>mdi-map</v-icon>
                </div>
                <div v-else>
                    <v-icon>mdi-map-marker</v-icon>
                </div>
                <!-- 地物の属性の有無に応じた表示名の切り替え -->
                <div v-if="item.properties.fclass">
                    {{ item.text }}({{ item.properties.fclass }}),
                    {{ item.address }}
                </div>
                <div v-else>{{ item.text }}, {{ item.address }}</div>
            </template>
        </v-autocomplete>
    </div>
</template>
<script>
import '@/assets/icon/style.css';

import { geocode } from '../api';

const isReverseGeocode = new RegExp('[0-9]+[.][0-9]+,[0-9]+[.][0-9]+', 's');

export default {
    name: 'SearchBar',
    data() {
        return {
            searchedItems: [],
            searchResult: {},
            searchingText: '',
        };
    },
    props: {
        mapState: {
            type: Object,
        },
        searchOption: {
            type: Object,
        },
        clickEvent: {
            type: Object,
        },
    },
    watch: {
        searchingText: {
            handler(val) {
                this.runGeocode(val);
            },
        },
        searchOption: {
            // 検索条件が変更された際は検索履歴をクリアする
            handler() {
                this.searchResult = {};
            },
        },
        mapState: {
            // 地図の表示状態に変更があった場合の処理
            handler() {
                if (this.searchOption.bbox) {
                    if (Object.keys(this.searchResult).length > 0) {
                        // BBOXモードで地図が遷移したら
                        this.searchResult = {};
                    }

                    if (
                        this.searchingText !== '' &&
                        !isReverseGeocode.test(this.searchingText)
                    ) {
                        // BBOXモードでかつ検索文字列があるなら再検索
                        // ただし逆ジオコーディングなら再検索しない
                        this.$refs.autocomplete.focus();
                        this.runGeocode(this.searchingText);
                    }
                }
            },
            deep: true,
        },
        clickEvent: {
            handler(val) {
                this.$refs.autocomplete.focus();
                const { lng, lat } = val;
                this.searchingText = `${lng},${lat}`;
            },
        },
    },
    computed: {
        searchBarWidth: function () {
            return window.innerWidth > 420 ? 400 : 310;
        },
    },
    methods: {
        async runGeocode(query) {
            if (query === '' || query === null) {
                this.searchedItems = [];
                return;
            }
            if (this.searchResult[query] !== undefined) {
                this.searchedItems = this.searchResult[query];
                return;
            }

            const bbox = this.searchOption.bbox
                ? `${this.mapState.bbox._sw.lng},${this.mapState.bbox._sw.lat},${this.mapState.bbox._ne.lng},${this.mapState.bbox._ne.lat} `
                : null;
            this.searchedItems = [];
            const result = await geocode(query, bbox);
            this.searchResult[query] = result.data.features;
            if (query === this.searchingText)
                this.searchedItems = this.searchResult[query];
        },
    },
};
</script>
