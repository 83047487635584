<template>
    <v-app id="app">
        <v-main id="container">
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
export default { name: 'App' };
</script>
