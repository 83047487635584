<template>
    <div id="home">
        <Header
            @setSelectedFeature="selectedFeature = $event"
            :mapState="mapState"
            :searchOption="searchOption"
            @setSearchOption="searchOption = $event"
        />
        <MapPane
            :selectedFeature="selectedFeature"
            @setMapBounds="mapState.bbox = $event"
            @clicked="clickEvent = $event"
        />
        <div id="search-bar-wrapper">
            <SearchBar
                @setSelectedFeature="selectedFeature = $event"
                :mapState="mapState"
                :searchOption="searchOption"
                :clickEvent="clickEvent"
            />
        </div>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import MapPane from '@/components/MapPane.vue';
import SearchBar from '@/components/SearchBar.vue';

export default {
    name: 'HomeView',
    data() {
        return {
            mapState: { bbox: null },
            searchOption: {
                bbox: false,
            },
            selectedFeature: null,
            clickEvent: null,
        };
    },
    components: {
        Header,
        MapPane,
        SearchBar,
    },
};
</script>

<style scoped>
#home {
    width: 100%;
    height: 100%;
}

#search-bar-wrapper {
    position: absolute;
    top: 12px;
    left: 12px;
}
</style>
