<template>
    <div>
        <v-app-bar app color="#005771" dark flat dense>
            <v-btn href="/" text>
                <v-icon left>icon-mieruneicon</v-icon>
                <h3 class="text-capitalize font-weight-regular ml-2">
                    MIERUNE Search ベータ版
                </h3>
            </v-btn>
            <v-spacer />
            <div>
                <v-btn text small @click="isOptionDialogShown = true">
                    <v-icon>mdi-cog</v-icon>
                </v-btn>
            </div>
        </v-app-bar>
        <v-dialog width="500" v-model="isOptionDialogShown" persistent>
            <v-card>
                <v-card-title class="headline grey lighten-2">
                    検索オプション
                </v-card-title>
                <v-card-text>
                    <!-- オプション項目 -->
                    <v-checkbox
                        label="表示範囲内のみ"
                        color="info"
                        hide-details
                        v-model="innerSearchOption"
                    ></v-checkbox>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        outlined
                        @click="isOptionDialogShown = false"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'TopHeader',
    data() {
        return {
            isOptionDialogShown: false,
        };
    },
    props: {
        searchOption: {
            type: Object,
            required: true,
        },
    },
    computed: {
        innerSearchOption: {
            get() {
                return this.searchOption.bbox;
            },
            set(val) {
                this.$emit('setSearchOption', { bbox: val });
            },
        },
    },
};
</script>
